import React from 'react';
import {ProjectAssignmentResponse} from '../app';
import {SM,Span} from '@zendeskgarden/react-typography';
import {Grid} from '@zendeskgarden/react-grid';
import ProjectAssignmentComponent from './ProjectAssignment.component';
import {Body, Cell, GroupRow, Row, Table} from '@zendeskgarden/react-tables';


type ProjectAssignmentsProp = {
    status: string
    assignments: Array<ProjectAssignmentResponse>
    rowSize: number
}

export default function ProjectAssignmentListComponent(props: ProjectAssignmentsProp) {

    return (
        <Table>
            <Body>
                <GroupRow data-testid="project-assignments-type-display">
                    <Cell colSpan={2}>
                        <Span isBold>{props.status.toUpperCase()}</Span>
                    </Cell>
                </GroupRow>
                <Row style={{ minHeight: 40 }} data-testid="project-assignments-display">
                    <Cell colSpan={2}>
                        {
                            props.assignments.length > 0
                                ?
                                <Grid>
                                    {props.assignments.map((assignment, key) => {
                                        return <ProjectAssignmentComponent
                                            key={key}
                                            assignment={assignment}
                                        />;
                                    })}
                                </Grid>
                                :
                                <SM><i>No {props.status} assignments</i></SM>
                        }
                    </Cell>
                </Row>
            </Body>
        </Table>
    );
}