import {EmployeeDetailsData, isAnyAttributeMissing} from './Employee.resource';
import {User} from '../zaf';
import {Col, Grid, Row} from '@zendeskgarden/react-grid';
import {Anchor} from '@zendeskgarden/react-buttons';
import {MD, Span} from '@zendeskgarden/react-typography';
import ProjectAssignmentListComponent from './ProjectAssignmentList.component';
import React, {useEffect, useState} from 'react';

type EmployeeDetailsProps = {
    employeeDetail: EmployeeDetailsData;
    currentEmployee: User
}

export function EmployeeDetails(props: EmployeeDetailsProps) {
    const rowSize = 12;
    const dataRowStyle = {marginBottom: 10};
    const [isDataMissing, setIsDataMissing] = useState<boolean>(false);
    const DATA_NOT_AVAILABLE = <span style={{fontStyle: 'italic'}}>Data Not Available</span>;

    useEffect(() => {
        setIsDataMissing(isAnyAttributeMissing(props.employeeDetail));
    }, [props.employeeDetail]);

    return <Grid gutters={false} data-testid="employee-details-display"
        style={{height: 400, overflowY: 'auto'}}>
        {isDataMissing &&
            <MD style={{...dataRowStyle, fontSize: '13px', fontStyle:'italic', color: 'rgb(173, 89, 24)'}} data-testid="warning">
                {'Oops! Looks like some fields could not be loaded. Check out '}
                <Anchor isExternal={true} href={`https://jigsaw.thoughtworks.net/consultants/${props.employeeDetail.employeeNumber}`} >
                    {'User\'s Jigsaw Profile'}
                </Anchor>
                {' for missing data.'}
            </MD>
        }
        <Row style={dataRowStyle} data-testid="name-display">
            <Col size={rowSize}>
                <MD><Span
                    isBold>Name: </Span> {props.employeeDetail && (props.employeeDetail.name || DATA_NOT_AVAILABLE)}
                </MD>
            </Col>
        </Row>
        <Row style={dataRowStyle} data-testid="email-display">
            <Col size={rowSize}>
                <MD><Span isBold>Email: </Span> {props.employeeDetail ? props.currentEmployee.email : ''}
                </MD>
            </Col>
        </Row>
        <Row style={dataRowStyle} data-testid="empno-display">
            <Col size={rowSize}>
                <MD><Span isBold>Employee
                    Number: </Span>{props.employeeDetail && (props.employeeDetail.employeeNumber || DATA_NOT_AVAILABLE)}
                </MD>
            </Col>
        </Row>
        <Row style={dataRowStyle} data-testid="status-display">
            <Col size={rowSize}>
                <MD><Span
                    isBold>Status: </Span>{props.employeeDetail && (props.employeeDetail.status || DATA_NOT_AVAILABLE)}
                </MD>
            </Col>
        </Row>
        <Row style={dataRowStyle} data-testid="role-display">
            <Col size={rowSize}>
                <MD><Span
                    isBold>Role: </Span>{props.employeeDetail && (props.employeeDetail.role || DATA_NOT_AVAILABLE)}</MD>
            </Col>
        </Row>
        <Row style={dataRowStyle} data-testid="grade-display">
            <Col size={rowSize}>
                <MD><Span
                    isBold>Grade: </Span>{props.employeeDetail && (props.employeeDetail.grade || DATA_NOT_AVAILABLE)}
                </MD>
            </Col>
        </Row>
        <Row style={dataRowStyle} data-testid="homeOffice-display">
            <Col size={rowSize}>
                <MD><Span isBold>Home
                    Office: </Span>{props.employeeDetail && (props.employeeDetail.homeOffice || DATA_NOT_AVAILABLE)}
                </MD>
            </Col>
        </Row>
        <Row style={dataRowStyle} data-testid="workingOffice-display">
            <Col size={rowSize}>
                <MD><Span isBold>Working
                    Office: </Span>{props.employeeDetail && (props.employeeDetail.workingOffice || DATA_NOT_AVAILABLE)}
                </MD>
            </Col>
        </Row>
        <Row>
            <Col size={rowSize}>
                {
                    (props.employeeDetail.currentAssignments && props.employeeDetail.futureAssignments) ?
                        <>
                            <MD isBold>Project Assignments: </MD>
                            <Grid gutters={false}
                                style={{marginTop: 10}}>
                                <ProjectAssignmentListComponent status={'Current'}
                                    assignments={props.employeeDetail.currentAssignments}
                                    rowSize={rowSize}/>
                                <ProjectAssignmentListComponent status={'Future'}
                                    assignments={props.employeeDetail.futureAssignments}
                                    rowSize={rowSize}/>
                            </Grid>
                        </>
                        : <MD><Span isBold>Project Assignments: </Span>{DATA_NOT_AVAILABLE}</MD>}
            </Col>
        </Row>
    </Grid>;
}