/* istanbul ignore file */
import {getZendeskClient} from './zendesk';
import {EmployeeDetailsResponse} from '../app';

export function getEmployeeDetails(employeeEmail: string): Promise<EmployeeDetailsResponse> {
    const settings = {
        url: `${process.env.REACT_APP_BFF_BASE_URL}/employees?employee_email=${employeeEmail}`,
        headers: {'x-api-key': getAccessToken()},
        type: 'GET',
        secure: isSecureSetting()
    };
    return getZendeskClient().request(settings).then(response => (response as unknown as EmployeeDetailsResponse));
}

function getAccessToken() {
    if (process.env.NODE_ENV !== 'development')
        return '{{setting.api_token}}';
    return process.env.BFF_ACCESS_TOKEN;
}

function isSecureSetting() {
    return process.env.NODE_ENV !== 'development';
}
