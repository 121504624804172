import React, {useEffect, useState} from 'react';
import {getTicketCollaborators, getTicketRequester} from '../resources/zendesk';
import {User} from '../zaf';
import EmployeeComponent from './Employee.component';

function AppComponent() {
    const [requester, setRequester] = useState<User>({name: '', email: '', id: 0, locale: '', groups: []});
    const [collaborators, setCollaborators] = useState([]);

    useEffect(() => {
        Promise.all([getTicketCollaborators(), getTicketRequester()])
            .then(([ticketCollaborators, requesterEmail])=>{
                setCollaborators(ticketCollaborators);
                setRequester(requesterEmail);
            });
    }, []);

    return (
        <div style={{width: '100vw', marginLeft: -8}}>
            { requester.id ?   <EmployeeComponent requester={requester} collaborators={collaborators}/> : null }
        </div>
    );
}

export default AppComponent;

