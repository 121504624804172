import React, {CSSProperties, useEffect, useRef, useState} from 'react';
// @ts-ignore
import _ from 'lodash';
import {Chrome} from '@zendeskgarden/react-chrome';
import {ThemeProvider} from '@zendeskgarden/react-theming';
import {SM, Span} from '@zendeskgarden/react-typography';
import {Grid, Row} from '@zendeskgarden/react-grid';
import {Alert, Paragraph, Title} from '@zendeskgarden/react-notifications';
import {Autocomplete, Dropdown, Field, Hint, Item, Label, Menu} from '@zendeskgarden/react-dropdowns';
import {Spinner} from '@zendeskgarden/react-loaders';
// @ts-ignore
import SearchIcon from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';


import {employeeDetailsByEmail, EmployeeDetailsData} from './Employee.resource';
import {User} from '../zaf';
import {Logo} from './logo';
import {EmployeeDetails} from './EmployeeDetails';

type EmployeeComponentProps = {
    requester: User,
    collaborators: Array<User>
}

function ErrorAlert(props: { currentEmployee: User, isThoughtworksEmail: boolean }) {
    return <Alert type="error" style={{height: 119, width: 218}} data-testid="error-alert-display">
        <Title>Oops!!</Title>
        <Paragraph data-testid="error-message-display">
            We cannot load the information for the user <Span
                isBold>{props.currentEmployee.email}</Span>
            {!props.isThoughtworksEmail &&
                <Span>, because the requester email is not from thoughtworks.com</Span>}
        </Paragraph>
    </Alert>;
}

function EmployeeComponent(props: EmployeeComponentProps) {
    const requester = props.requester;
    const collaborators = props.collaborators;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [employeeDetail, setEmployeeDetail] = useState<EmployeeDetailsData>();
    const [currentEmployee, setCurrentEmployee] = useState<User>(requester);
    const [inputValue, setInputValue] = useState('');
    const [matchingOptions, setMatchingOptions] = useState<Array<User>>([requester, ...collaborators]);

    const poweredByStyle: CSSProperties = {
        position: 'absolute',
        right: '8px',
        fontSize: '10px',
        color: 'gray',
        top: '0px',
        fontFamily: 'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",Arial,sans-serif'
    };

    const collaboratorsRef = useRef([requester, ...collaborators]);

    const filterMatchingOptionsRef = useRef(
        _.debounce((value: string) => {
            const matchedOptions = collaboratorsRef.current.filter(
                collaborator => {
                    if (!value.length)
                        return true;

                    if (collaborator.email.includes(value) || collaborator.name.includes(value))
                        return true;
                }
            );
            setMatchingOptions(matchedOptions);
        }, 300)
    );

    useEffect(() => {
        filterMatchingOptionsRef.current(inputValue);
    }, [inputValue]);

    const [invalidUser, setInvalidUser] = useState<boolean>();

    useEffect(() => {
        setCurrentEmployee(props.requester);
    }, [props.requester]);

    const isThoughtworksEmail = (email: string) => {
        return email.toLocaleLowerCase()?.endsWith('@thoughtworks.com');
    };
    useEffect(() => {
        if (currentEmployee?.email) {
            if (isThoughtworksEmail(currentEmployee.email)) {
                setIsLoading(true);
                setInvalidUser(false);
                employeeDetailsByEmail(currentEmployee.email)
                    .then(data => {
                        setEmployeeDetail(data);
                    }).catch(() => {
                        setInvalidUser(true);
                    }).finally(() => setIsLoading(false));
            }
        }
    }, [currentEmployee]);

    return (
        <>
            <span data-testid="powered-by" style={poweredByStyle}>Powered by&nbsp;
                <b>
                    <Logo size={10} style={{position: 'relative', top: '1px'}}></Logo>
                    <span style={{color: '#634F7D'}}>&nbsp;myTW</span>
                </b>
            </span>
            <ThemeProvider>
                <Chrome>

                    <Grid gutters={false}>
                        <Row style={{marginBottom: 20}}>

                            <Dropdown
                                inputValue={inputValue}
                                selectedItem={currentEmployee.id}
                                onSelect={selectedId => {
                                    const employee: User = [requester, ...collaborators].find(item => item.id === selectedId)!;
                                    setCurrentEmployee(employee);
                                }
                                }
                                onInputValueChange={value => setInputValue(value)}
                                downshiftProps={{defaultHighlightedIndex: 0}}
                            >

                                <Field>
                                    <Label>Choose Employee</Label>
                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                    <Hint><SM>Only requester & CC'ed employees are shown</SM></Hint>
                                    <Autocomplete start={<img src={SearchIcon}/>}
                                        data-testid="employee-search">{currentEmployee.name}</Autocomplete>
                                </Field>
                                <Menu data-testid="employee-search-options">
                                    {matchingOptions.length ? (
                                        matchingOptions.map(option => {
                                            return (
                                                <Item key={option.id} value={option.id}>
                                                    <span>{option.name} <br/> {option.email}</span>
                                                </Item>
                                            );
                                        })
                                    ) : (
                                        <Item disabled>No matches found</Item>
                                    )}
                                </Menu>
                            </Dropdown>
                        </Row>
                        {
                            (isLoading && <Spinner data-testid={'spinner'} size={'40'}/>)
                            ||
                            ((invalidUser || !isThoughtworksEmail(currentEmployee.email)) &&
                                <ErrorAlert currentEmployee={currentEmployee}
                                    isThoughtworksEmail={isThoughtworksEmail(currentEmployee.email)}/>)
                            ||
                            (employeeDetail &&
                                <EmployeeDetails currentEmployee={currentEmployee} employeeDetail={employeeDetail}/>)
                        }
                    </Grid>
                </Chrome>
            </ThemeProvider>
        </>
    );
}


export default EmployeeComponent;
