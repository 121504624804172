import React from 'react';
import ReactDOM from 'react-dom';
import AppComponent from './components/App.component';
import { onAppRegistered } from './resources/zendesk';

import ZendeskClient from './zaf';

function renderRoot(element: JSX.Element) {
    ReactDOM.render(element, document.getElementById('employee-details'));
}

/* Eslint rules are intentionally disabled for supporting external ZAF library and storing it as a global variable*/

function initialise() {
    // @ts-ignore
    const client= initZendeskClient();

    if (client) {
        //eslint-disable-next-line
        (window as any)['ZendeskClient'] = client;
        onAppRegistered(function () {
            renderRoot(<AppComponent />);
        });
    } else {
        renderRoot(<h1>Problem loading app...</h1>);
    }
}

function initZendeskClient(): ZendeskClient|unknown{
    if(document.defaultView && (document.defaultView as never)['ZAFClient']){
        return ((document.defaultView as never)['ZAFClient'] as typeof ZendeskClient).init();
    }
}

setTimeout(initialise,500);