import React from 'react';
import {SM} from '@zendeskgarden/react-typography';
import {ProjectAssignmentResponse} from '../app';
import {Col, Row} from '@zendeskgarden/react-grid';



type ProjectAssignmentComponentProps = {
    assignment: ProjectAssignmentResponse
}
export default function ProjectAssignmentComponent(props: ProjectAssignmentComponentProps) {

    return (
        <>
            <Row style={{ minHeight: 60 }}>
                <Col>
                    <SM data-testid={'project-assignment-account-display'}> 
                        {props.assignment.account.name}
                    </SM>
                    <SM data-testid={'project-assignment-project-display'}>
                        {props.assignment.project.name}
                    </SM>
                </Col>
                <Col>
                    <SM data-testid={'project-assignment-start-display'}>
                        {props.assignment.duration.startsOn} to
                    </SM>
                    <SM data-testid={'project-assignment-end-display'}>
                        {props.assignment.duration.endsOn}
                    </SM>
                </Col>
            </Row>

        </>
    );
}