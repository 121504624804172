import {EmployeeDetailsResponse, ProjectAssignmentResponse} from '../app';
import {getEmployeeDetails} from '../resources/bff';

export type EmployeeDetailsData = {
    name?: string,
    employeeNumber: string,
    role?: string,
    grade?: string,
    homeOffice?: string,
    workingOffice?: string,
    currentAssignments?: Array<ProjectAssignmentResponse>,
    futureAssignments?: Array<ProjectAssignmentResponse>,
    status?: string
}

export function employeeDetailsByEmail(email: string): Promise<EmployeeDetailsData>{
    return getEmployeeDetails(email).then(employeeDetailsData);
}

export function employeeDetailsData(employee: EmployeeDetailsResponse): EmployeeDetailsData{
    return {
        name: employee.name,
        employeeNumber: employee.employeeNumber,
        role: employee.role,
        grade: employee.grade,
        homeOffice: employee.homeOffice,
        workingOffice: employee.workingOffice,
        currentAssignments: employee.assignments?filterAssignmentByStatus(employee.assignments, 'current'):undefined,
        futureAssignments: employee.assignments?filterAssignmentByStatus(employee.assignments, 'future'):undefined,
        status: employee.status
    };
}

function filterAssignmentByStatus(assignments: Array<ProjectAssignmentResponse>, assignmentStatus: string): Array<ProjectAssignmentResponse> {
    return assignments.filter(e => e.status == assignmentStatus);
}

export function isAnyAttributeMissing(data: EmployeeDetailsData): boolean{
    return Object.values(data).some(value=>value==undefined);
}